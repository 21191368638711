import React from "react"
import { Helmet } from "react-helmet"

import Footer from "./Footer"
import Header from "./Header"

import "../assets/css/normalize.css"
import "../assets/scss/main.scss"

const Layout = ({ lang = "en", children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CANHK | Welcome to Canada</title>
      </Helmet>
      <header>
        <Header lang={lang} />
      </header>
      {children}
      <Footer />
    </>
  )
}

export default Layout
