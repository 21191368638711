import React from "react"

const Footer = () => {
  return (
    <footer>
      CANHK is not responsible for the content or access to the external links.
      Copyright {new Date().getFullYear()} CANHK. Powered by Kingdomdev
    </footer>
  )
}

export default Footer
