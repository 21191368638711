import { Link } from "gatsby"
import React from "react"
import * as util from "../common/util"
import * as data from "../assets/js/data"

const copy = {
  en: {
    connecting: "Connecting",
    information: "Information",
    about: "About",
    translate: "中文",
  },
  cn: {
    connecting: "聯絡",
    information: "資訊",
    about: "關於我們",
    translate: "English",
  },
}

const Navbar = ({ lang, handleMenuToggle }) => {
  const translate = key => copy[lang][key]
  const topics = data.topicCopy[lang]
  const isChinese = lang === "cn"
  const url = util.getUrl()
  const translateLink = url
    ? isChinese
      ? url.pathname.replace("/cn", "") + url.search
      : `/cn${url.pathname}` + url.search
    : null

  return (
    <ul className="nav__list collapsible__content">
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to={isChinese ? "/cn/connect/" : "/connect/"}>
          {translate("connecting")}
        </Link>
      </li>
      <li className="nav__item nav__dropdown" onClick={handleMenuToggle}>
        <Link to={isChinese ? "/cn/information/" : "/information/"}>
          {translate("information")}
        </Link>
        <div className="nav__dropdown-content">
          {Object.entries(topics).map(([id, topic]) => (
            <a
              key={id}
              href={
                isChinese
                  ? `/cn/information/topic/?id=${id}`
                  : `/information/topic/?id=${id}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {topic.title}
            </a>
          ))}
        </div>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to={isChinese ? "/cn/about/" : "/about/"}>
          {translate("about")}
        </Link>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to={translateLink}>{translate("translate")}</Link>
      </li>
    </ul>
  )
}

export default Navbar
