export const topicCopy = {
  en: {
    housing: {
      title: "Housing",
      description: "A place for your family",
      body:
        "Canada is a big country with many interesting places to live. Wherever you end up, your family will need a home. Purchase and rental prices are usually lower than in Hong Kong, except perhaps in large cities like Toronto or Hong Kong. This section includes information about renting or buying a place, as well as information on the different housing markets across the country.",
    },
    health: {
      title: "Health",
      description: "A local doctor",
      body:
        "Canada has a good health care system, with many medical services provided free by the government for citizens, permanent residents and others. Health care is a provincial responsibility and so provisions differ between provinces. Registering for health care also differs from province to province. Once your access to public health care has been approved, you will want to find a local doctor, who will help you and your family benefit from Canada’s public health care. This section includes information about the different provincial health programs as well as instructions for applying for coverage.",
    },
    education: {
      title: "Education",
      description: "A school for my kids",
      body:
        "Our children’s education is a high-priority for every parent. Canada’s provides free education for kindergarten through grade twelve (usually five years old through eighteen). Preschools are available for younger children but usually at a cost. Canada’s education system emphasizes academic excellence in an open environment where children are encouraged to express themselves. While Canadian schools are generally very good, the quality of individual schools varies from community to community. It is important to find out as much as you can about the schools near where you are planning to live. This section includes information about Canada’s educational system and directions for exploring the school systems in your new neighbourhood.",
    },
    finance: {
      title: "Finance",
      description: "Managing your finances",
      body:
        "Banking can be complicated, especially when dealing with banking systems in different countries. Canadian banks are well established and easily accessible globally, but it is important to understand banking regulations in your new country. This section includes information on how to set up and manage the bank accounts you will need.",
    },
    transportation: {
      title: "Transportation",
      description: "Getting around",
      body:
        "Canada is a big country and most Canadian families own at least one car. Major cities have reasonable public transit systems although Canadian public transit is not as good as in Hong Kong. Uber, Lyft and other ride-sharing services are available in urban areas, as are traditional taxis. If you are moving to a smaller city or more rural setting, you will need a good vehicle, especially during the winter months. Winter driving conditions will vary depending on where you are located. This section includes information on public transit as well as on purchasing a used or new vehicle.",
    },
    employment: {
      title: "Employment",
      description: "A job",
      body:
        "Canada welcomes thousands of new arrivals every year and celebrates their contribution to the workforce. Looking for a new job can be challenging but there are many resources available to help you find a position that fits your need and skills. Connections through family and friends can be very helpful as you look for a new job, and there are many organizations that will also help. This section includes information on job opportunities across Canada, how to explore those and where to find support during your job search.",
    },
    neighbour: {
      title: "Neighbour",
      description: "A local helper",
      body:
        "One of the hardest things about leaving home is the fear of losing relationships with our friends and neighbours, but a new country offers the prospect of new friends. Many of your new neighbours will have their own stories from when they or their families came to Canada. They will be able to help you find your way around your new community, figure out where to shop and help you connect with local services. This section includes information on how to build relationships as a new arrival in Canada.",
    },
    community: {
      title: "Community",
      description: "A local church",
      body:
        "All of us need community, people who care for us and will support us on our journey. There are thousands of people across Canada who are excited to welcome you to their neighbourhood and interested in helping you get settled. This section will help connect you with them.",
    },
    activities: {
      title: "Activities",
      description: "Things to do",
      body:
        "Canada is a large, diverse country with lots to see and do. You will be able to find an amazing variety of cultures and foods in most of our urban centres, or you may prefer to leave the cities to explore the Canadian lakes, forests and mountains. This section includes information on a wide variety of activities which we hope you will try as you get to know your new home.",
    },
    immigration: {
      title: "Immigration",
      description: "Staying in Canada",
      body:
        "Canada is a nation of immigrants, something we remember as we pay respect to the first nations who lived on this land long before it became Canada. The Canadian government welcomes new arrivals from Hong Kong and has provided a number of ways in which you can become citizens. This section includes information on visa requirements and the different paths to citizenship.",
    },
  },
  cn: {
    housing: {
      title: "房屋",
      description: null,
      body:
        "加拿大是一個偌大的國家，有很多美好的地方可以居住。無論您最後選擇在哪個城市居住，您和家人都需要一個屬於自己的家。除了多倫多或溫哥華等大城市外，在加拿大購買和租賃價格通常都低於香港。本部分包括有關租用或購買地方的資訊，以及有關全國不同房屋市場的信息，可供參考。",
    },
    health: {
      title: "醫療",
      description: null,
      body:
        "加拿大擁有完善的醫療保健系統，政府為公民、永久居民和其他合資格的人免費提供許多醫療服務。醫療保健是省級責任，因此各省之間的規定有所不同，故註冊醫療保健也因省而異。一旦您獲得接受公共醫療保健的資格，您需要找到一位當地醫生，他將幫助您和家人從加拿大的公共醫療保健計劃中受益。本部份包括有關不同省份保健計劃的資訊以及申請程序的說明。",
    },
    education: {
      title: "教育",
      description: null,
      body:
        "孩子的教育是每個父母的重中之重。加拿大為幼兒園到十二年級（通常是5歲到18歲）提供免費教育。幼兒園可供年幼的孩子入讀，但通常需要付費。加拿大的教育系統強調在開放環境中鼓勵兒童表達自己，在學術和體藝上按孩子天賦發展。雖然加拿大的學校一般都很好，但個別學校的質素會因社區而異。盡可能多地了解您計劃居住地區附近的學校是很重要的。本部份包括有關加拿大教育系統的資訊，以及探索社區學校系統的方向。",
    },
    finance: {
      title: "財務",
      description: null,
      body:
        "銀行業務可能很複雜，尤其是在處理不同國家的銀行交易時。加拿大銀行在全球建立了穩健且易於交易的系統，但了解您所在新國家/地區的銀行法規非常重要。本部份包含有關如何設置和管理您需要的銀行賬戶的信息。",
    },
    transportation: {
      title: "交通",
      description: null,
      body:
        "加拿大是一個地沿偌大的國家，大多數加拿大家庭至少擁有一部汽車。儘管加拿大的公共交通不如香港，但主要城市都有完備的公共交通系統。Uber、Lyft和其他接載服務在城市地區都可用，傳統出租車也是如此。如果您要搬到較小的城市或更偏遠的農村地區，您將需要一輛好車，尤其是在冬季。冬季駕駛條件會因您所在的位置而異。本部份包括有關公共交通以及購買二手車或新車的信息。",
    },
    employment: {
      title: "就業",
      description: null,
      body:
        "加拿大每年都歡迎成千上萬的新移民，並歡迎他們對勞動力的貢獻。尋找新工作可能具有挑戰性，但有許多資源可以幫助您找到適合您需求和技能的職位。在您尋找新工作時，通過家人和朋友建立聯繫會非常有幫助，而且有許多組織也會提供協助。本部份包括有關加拿大各地工作機會的資訊、如何探索這些機會以及在求職期間從何處獲得支持。",
    },
    neighbour: {
      title: "鄰舍​",
      description: null,
      body:
        "到外國居住最難的事情之一是害怕失去與朋友和鄰居的關係，但一個新的國家提供了結交新朋友的機會。您許多的新鄰居或他們的家人來到加拿大時都會有自己的故事。他們將能夠幫助您在新社區中適應下來、找合適購物的地點，並幫助您與當地服務聯繫。本部份包含有關如何在新抵達加拿大時建立關係的資訊。",
    },
    community: {
      title: "社區",
      description: null,
      body:
        "我們所有人都需要於於自己的社區，需要關心我們並將在新生活中支持我們的人。加拿大有成千上萬的人很高興並歡迎您來到他們的社區，極想幫助您安頓下來。無論您是需要幫助學習英語或法語、聯繫當地服務機構，還是只想找人傾談，他們都可以提供協助。本部份將幫助您與當地教會或社區團體建立聯繫。",
    },
    activities: {
      title: "活動",
      description: null,
      body:
        "加拿大是一個廣大遼闊、多元化的國家，有很多景點和活動。您將在大部分城市中找到令人驚嘆的各種文化和美食，或者您可能更願意離開城市去探索加拿大的湖泊、森林和山脈。本部份包含有關各種活動的信息，我們希望您在了解新家時，也會積極嘗試這些加拿大活動。",
    },
    immigration: {
      title: "移民",
      description: null,
      body:
        "加拿大是一個移民國家，我們要記得這一點，因為我們尊重在這片土地成為加拿大之前，很久就生活在這裡的第一批民族。加拿大政府歡迎來自香港的新移民，並提供了多種方式讓您成為公民。本部份包括有關簽證要求和獲得公民身份的不同途徑的信息。",
    },
  },
}

export const topicLinks = {
  housing: [
    {
      text: "Rental: Information for renting accommodation across Canada",
      url: "https://www.realtor.ca",
    },
    {
      text: "Ontario Landlord and Tenancy Board",
      url: "https://tribunalsontario.ca/ltb/",
    },
    {
      text: "Ontario Landlord and Tenancy Act",
      url:
        "https://settlement.org/ontario/housing/rent-a-home/tenant-rights-and-responsibilities/what-are-my-rights-as-a-tenant/",
    },
    {
      text: "Ontario Landlord and Tenancy Rules",
      url: "https://www.ontario.ca/laws/statute/06r17",
    },
    {
      text: "BC Tenancy Laws & Rules",
      url:
        "https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies/calculators-and-resources/the-law",
    },
    {
      text: "Information for tenants and landlords | Alberta.ca",
      url:
        "https://www.alberta.ca/information-tenants-landlords.aspx#jumplinks-0",
    },
    {
      text: "Mortgage Calculator",
      url:
        "https://www.cmhc-schl.gc.ca/en/consumers/home-buying/calculators/mortgage-calculator",
    },
    {
      text: "First-Time Home Buyer Incentive",
      url:
        "https://www.cmhc-schl.gc.ca/en/consumers/home-buying/first-time-home-buyer-incentive",
    },
    {
      text: "CREA Housing Market National Statistics",
      url: " https://www.crea.ca/housing-market-stats/",
    },
    {
      text: "Information on housing in Ontario (CICS, in Chinese) page 23-26",
      url:
        "https://app.cicscanada.com/newcomer_resource_guide/newcomer_resource_guide_chi.html",
    },
  ],
  health: [
    {
      text:
        "Information on Health programs in different Provinces 加拿大各省保健計劃, 官方網頁",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/health-care-card.html#ministries",
    },
    {
      text: "Ontario 安省健康保健計劃",
      url: "https://www.ontario.ca/page/apply-ohip-and-get-health-card",
    },
    {
      text:
        "華諮處 新移民指南  醫療保健篇 CICS Resource Guide page 40 on health care",
      url:
        "https://app.cicscanada.com/newcomer_resource_guide/newcomer_resource_guide_chi.html",
    },
    {
      text: "Find a doctor or nurse practitioner",
      url:
        "https://www.ontario.ca/page/find-family-doctor-or-nurse-practitioner",
    },
    {
      text: "Get medical advice: Telehealth Ontario",
      url: "https://www.ontario.ca/page/get-medical-advice-telehealth-ontario",
    },
  ],
  education: [
    {
      text: "加拿大教育 Education in Canada",
    },
    {
      text: "各省教育網站 (Education in different Provinces)",
      url: "https://www.cmec.ca/299/education-in-canada-an-overview/index.html",
    },
    {
      text: "安省 Ontario",
      url: "https://peopleforeducation.ca/public-education-in-ontario/",
    },
    {
      text:
        "華諮處 新移民指南 教育篇    CICS Resource Guide page 20 - 26 on Canada Education",
      url:
        "https://app.cicscanada.com/newcomer_resource_guide/newcomer_resource_guide_chi.html",
    },
    {
      text:
        "學校分類 (Levels of Education) Education in Canada: What it's like",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/education/types-school.html",
    },
    {
      text: "成人教育 (Adult Education)",
      url: "https://settlement.org/ontario/education/",
    },
  ],
  finance: [
    {
      text: "稅務 Taxation (華諮處提供中文資料) page 34",
      url:
        "https://app.cicscanada.com/newcomer_resource_guide/newcomer_resource_guide_chi.html",
    },
    {
      text: "社會計劃/援助 Social Assistance (華諮處提供中文資料) page 35-38",
      url:
        "https://app.cicscanada.com/newcomer_resource_guide/newcomer_resource_guide_chi.html",
    },
    {
      text: "各大銀行網頁 Major Banks",
    },
    {
      text: "BofC",
      url:
        "https://www.bankofchina.com/ca/pbservice/pbo/201403/t20140331_3118649.html",
    },
    {
      text: "BMO",
      url: "https://www.bmo.com/chinese/",
    },
    {
      text: "CIBC",
      url: "https://newcomer.cibc.com/zh/moving-to-canada",
    },
    {
      text: "HSBC",
      url:
        "https://www.hsbc.ca/new-to-canada/applying-from-outside-of-canada/#",
    },
    {
      text: "National Bank",
      url: "https://www.nbc.ca/personal/accounts/newcomers.html ",
    },
    {
      text: "RBC",
      url: "https://www.rbc.com/newcomers-chinese/",
    },
    {
      text: "TD",
      url: "https://zt.td.com/ca/en/personal-banking/solutions/new-to-canada/",
    },
    {
      text: "Scotiabank",
      url: "https://schinese.startright.scotiabank.com/",
    },
    {
      text: "信用卡 Credit Card",
    },
    {
      text: "TD Visa",
      url: "https://zt.td.com/ca/en/personal-banking/products/credit-cards/",
    },
    {
      text: "BMO",
      url: "https://www.bmo.com/main/personal/credit-cards/",
    },
    {
      text: "RBC",
      url:
        "https://www.rbc.com/newcomers-chinese/credit-cards-for-newcomers.html",
    },
    {
      text: "HSBC",
      url: "https://www.hsbc.ca/credit-cards/",
    },
    {
      text: "Tangerine",
      url: "https://www.tangerine.ca/en/products/spending/creditcard/",
    },
  ],
  transportation: [
    { text: "公共交通系統 Urban transit information" },
    {
      text: "安省 跨市長途巴士 Go Transit",
      url: "https://www.gotransit.com/",
    },
    {
      text: "安省機場穿梭列車 Union-Pearson Express (UP Express)",
      url: "https://www.upexpress.com/",
    },
    { text: "安省 大多倫多市 Greater Toronto Area in Ontario" },
    { text: "Toronto Transit Commission (TTC)", url: "http://www.ttc.ca/" },
    {
      text: "Brampton Transit",
      url: "https://www.brampton.ca/en/residents/transit/Pages/Home.aspx",
    },
    {
      text: "Mississauga Transit (MiWAY)",
      url: "https://www.mississauga.ca/miway-transit/",
    },
    {
      text: "Durham Region Transit (DRT)",
      url: "https://www.durhamregiontransit.com/en/index.aspx",
    },
    {
      text: "Grand River Transit (GRT)",
      url: "https://www.grt.ca/en/index.aspx",
    },
    {
      text: "Niagara Region Transit (NRT)",
      url: "https://www.niagararegion.ca/transit/default.aspx",
    },
    {
      text: "NRT OnDemand",
      url: "https://niagararegion.ca/transit/on-demand/default.aspx",
    },
    {
      text: "Niagara Falls Transit",
      url: "https://niagarafalls.ca/living/transit/",
    },
    { text: "St-Catherines Transit", url: "https://yourbus.com/" },
    { text: "Welland Transit", url: "https://www.welland.ca/transit/" },
    { text: "Fort Erie Transit", url: "https://www.fetransit.ca/" },
    { text: "City of Ottawa: OC Transpo", url: "https://www.octranspo.com/" },
    {
      text: "City of Kingston",
      url: "https://www.cityofkingston.ca/residents/transit",
    },
    {
      text: "City of Peterborough Peterborough Transit",
      url: "https://www.peterborough.ca/en/city-services/transit.aspx",
    },
    {
      text: "City of Hamilton HSR Bus Schedules & Fares",
      url: "https://www.hamilton.ca/hsr-bus-schedules-fares",
    },
    {
      text: "City of Guelph",
      url: "https://guelph.ca/living/getting-around/bus/",
    },
    {
      text: "City of London London Transit Commission",
      url: "https://www.londontransit.ca/",
    },
    {
      text: "City of Windsor Transit Windsor",
      url:
        "https://www.citywindsor.ca/residents/transitwindsor/Pages/Transit-Windsor.aspx",
    },
    { text: "溫哥華 Vancouver" },
    { text: "TransLink", url: "https://www.translink.ca/" },
    { text: "BC Transit", url: "https://www.bctransit.com/victoria/home" },
    { text: "Alberta" },
    {
      text: "Calgary Transit",
      url: "https://www.calgarytransit.com/home.html",
    },
    {
      text: "Edmonton Transit Service (ETS)",
      url: "https://www.edmonton.ca/edmonton-transit-system-ets",
    },
    { text: "Quebec" },
    { text: "STM", url: "https://www.stm.info/en" },
    {
      text: "City of Laval:  Société de transport de Laval (STL)",
      url: "https://stlaval.ca/home",
    },
    {
      text: "Urban agglomeration of Longueuil (RTL)",
      url: "https://www.rtl-longueuil.qc.ca/en-CA/home-page/",
    },
    {
      text: "Réseau de transport de la Capitale (RTC)",
      url: "https://www.rtcquebec.ca/en",
    },
    { text: "Saskatchewan" },
    { text: "Saskatoon Transit", url: "https://transit.saskatoon.ca/" },
    {
      text: "Regina Transit",
      url: "https://www.regina.ca/transportation-roads-parking/transit/",
    },
    { text: "Manitoba" },
    { text: "Winnipeg Transit", url: "https://winnipegtransit.com/en" },
    { text: "Atlantic Region" },
    {
      text: "Fredericton Transit",
      url: "https://www.fredericton.ca/en/transit",
    },
    {
      text: "Halifax Transit",
      url: "https://www.halifax.ca/transportation/halifax-transit",
    },
    { text: "T3 Transit", url: "https://www.t3transit.ca/" },
    { text: "Metrobus Transit", url: "https://www.metrobus.com/home/" },
    { text: "駕駛執照 Driver’s licence" },
    {
      text: "安省 Ontario",
      url: "https://www.ontario.ca/page/get-g-drivers-licence-new-drivers",
    },
    {
      text:
        "For current licence-holders wishing to switch to an Ontario license",
      url: "https://www.ontario.ca/page/exchange-out-province-drivers-licence",
    },
    {
      text:
        "BC British Columbia: The Insurance Corporation of British Columbia (ICBC): Driver licensing & ID",
      url: "https://www.icbc.com/driver-licensing/Pages/Default.aspx",
    },
    {
      text: "For current licence-holders wishing to switch to a B.C. licence",
      url:
        "https://www.icbc.com/driver-licensing/moving-bc/Pages/Moving-from-another-country.aspx",
    },
    {
      text: "Alberta",
      url: "https://www.alberta.ca/get-drivers-licence.aspx",
    },
    {
      text:
        "For current licence-holders wishing to switch to an Alberta licence",
      url: "https://www.alberta.ca/exchange-non-alberta-licences.aspx",
    },
    { text: "汽車保險 Auto Insurance Bureau of Canada" },
    { text: "British Columbia", url: "http://www.ibc.ca/bc/auto" },
    { text: "Ontario", url: "http://www.ibc.ca/on/auto" },
    { text: "Alberta", url: "http://www.ibc.ca/ab/auto" },
    { text: "Saskatchewan", url: "http://www.ibc.ca/sk/auto" },
    { text: "Manitoba", url: "http://www.ibc.ca/mb/auto" },
    { text: "Quebec", url: "http://www.ibc.ca/qc/auto" },
    { text: "New Brunswick", url: "http://www.ibc.ca/nb/auto" },
    { text: "Nova Scotia", url: "http://www.ibc.ca/ns/auto" },
    { text: "Prince Edward Island", url: "http://www.ibc.ca/pe/auto" },
    { text: "Newfoundland and Labrador", url: "http://www.ibc.ca/nl/auto" },
  ],
  employment: null,
  neighbour: null,
  community: [
    {
      text:
        "安省社區服務機構 Ontario Ministry of Children, Community and Social Services",
      url: "https://www.mcss.gov.on.ca/en/mcss/",
    },
    {
      text: "亞伯特省社區服務機構 Calgary China Town",
      url: "https://www.calgarychinatown.com/",
    },
    {
      text:
        "安省移民/留學諮詢 Information for newcomers like immigrants, international students- 華咨處 Centre for Immigrants and Community Services",
      url: "https://www.cicscanada.com/en/",
    },
    {
      text: "輔導機構: 家和服務 Community Family Services of Ontario",
      url: "https://cfso.care/",
    },
    {
      text: "康福心理健康協會 Hong Fook Mental Health Association",
      url: "https://www.hongfook.ca/",
    },
    {
      text: "長者服務",
    },
    {
      text: "頤康中心 Yee Hong Centre for Geriatric Care",
      url: "https://www.yeehong.com/",
    },
    {
      text: "耆暉會 Carefirst Seniors & Community Services Association",
      url: "https://carefirstontario.ca/",
    },
    {
      text: "孟嘗會 Mon Sheong Foundation",
      url: "https://www.monsheong.org/",
    },
    {
      text: "多華會 The Cross-Cultural Community Services Association",
      url: "http://tccsa.on.ca/zh/",
    },
    {
      text: "其他社區服務機構",
    },
    {
      text: "約克區移民服務中心 Welcome Centres",
      url: "http://www.welcomecentre.ca",
    },
    {
      text: "Parkdale Intercultural Association",
      url: "https://piaparkdale.com",
    },
    {
      text: "105 Gibson Centre",
      url: "https://105gibson.com/",
    },
    {
      text: "Support Enhance Access Centre",
      url: "https://seascentre.org",
    },
  ],
  activities: null,
  immigration: [
    {
      text: "Federal immigration information",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada.html",
    },
    {
      text: "Provincial immigration offices",
    },
    {
      text: "British Columbia",
      url: "https://www.welcomebc.ca/Immigrate-to-B-C",
    },
    {
      text: "Alberta",
      url: "https://www.alberta.ca/immigration.aspx",
    },
    {
      text: "Saskatchewan",
      url:
        "https://www.saskatchewan.ca/residents/moving-to-saskatchewan/live-in-saskatchewan/by-immigrating/saskatchewan-immigrant-nominee-program",
    },
    {
      text: "Manitoba",
      url: "https://immigratemanitoba.com/immigrate-to-manitoba",
    },
    {
      text: "Ontario",
      url: "https://www.ontario.ca/page/immigrate-to-ontario",
    },
    {
      text: "Quebec",
      url: "https://www.quebec.ca/en/immigration",
    },
    {
      text: "New Brunswick",
      url:
        "https://www.welcomenb.ca/content/wel-bien/en/immigration_programs.html",
    },
    {
      text: "Nova Scotia",
      url: "https://novascotiaimmigration.com/",
    },
    {
      text: "Prince Edward Island",
      url: "https://www.princeedwardisland.ca/en/topic/office-immigration",
    },
    {
      text: "Newfoundland and Labrador",
      url:
        "https://www.gov.nl.ca/immigration/immigrating-to-newfoundland-and-labrador/",
    },
    {
      text: "Yukon",
      url: "https://yukon.ca/en/immigrate-yukon",
    },
    {
      text: "Northwest Territories",
      url: "https://www.immigratenwt.ca/en/immigrate-here",
    },
  ],
}

export const topicVideoUrls = {
  housing:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rrnTh2LSPgQDoHHzWFZkMrq",
  health:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rr_JqYBUsPQlAo-EfJuVmQo",
  education:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rrVlmmnUOcN6W0nJ9-356C7",
  finance:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rrU5AmRF-0H1hTznOf3yY1M",
  transportation:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rr9hCy6-F6FbChpGlRvHk-8",
  employment:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4roc9_bE0c4373eLJ9uCvSSC",
  neighbour:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rp6iGfQa-kfP2-angmF1oUR",
  community:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4roCeIygNcia0jfOiHtej-_c",
  activities:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rrMgQTVn40TuoFhaFBm9NgG",
  immigration:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqohXWj5H7-OLbVZUOljVsM",
}
